body {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12pt;
  line-height: 14pt;

  display: grid;
  grid-template-areas:
    "header header header"
    "mainSection mainSection mainSection"
    "footer footer footer";
  grid-template-rows: auto 1fr 60px;
  grid-template-columns: 20% 1fr 15%;
  grid-gap: 10px;
  height: 100vh;
  margin: 0;
}

a {
  color: #5D125B;
  text-decoration: underline;
}

a:hover {
  color: #5D125B;
  text-decoration: none;
}

#pageHeader {
  grid-area: header;
  background-color: #42004C;
  background-image: url("../img/header-bg.png");
  background-repeat: repeat-x;
  background-position: bottom center;
  font-size: 14pt;
  line-height: 16pt;
}

#pageHeader .contacts {
  width: 100%;
  height: 50px;
  background-color: #230029;
  color: #FFFFFF;
}

#pageHeader .contacts .container {
  max-width: 1800px;
  padding: 0 10px;
  margin: 0 auto;
  display: flex;
  gap: 15px;
  align-content: space-between;
}

#pageHeader .contacts .container ul,
#pageHeader .contacts .container .search,
#pageFooter .container .search{
  display: flex;
  gap: 25px;
}

#pageHeader .contacts .container ul {
  width: 70%;
  list-style: none;
  margin: 0;
  padding: 15px 0 0;
}

#pageHeader .contacts .container .search,
#pageFooter .container .search{
  gap: 15px;
  width: 30%;
  padding: 7px 0 0;
  align-content: space-between;
}

#pageHeader .contacts .container .search .locale-link,
#pageFooter .container .search .locale-link{
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  padding: 2px
}

#pageHeader .contacts .container .search .locale-link:hover,
#pageFooter .container .search .locale-link:hover{
  color: #ccc;
  border-color: #ccc;
}

#pageHeader .contacts .container .search form,
#pageFooter .container .search form{
  width: 100%;
  display: flex;
  gap: 0;
  align-items: stretch;
}

#pageHeader .contacts .container .search form input,
#pageFooter .container .search form input{
  width: 100%;
  height: 32px;
  padding: 0 5px;
  margin: 0;
  border: 0;
  border-right: 2px solid #230029;
  background: #E5E5E5;
  outline: none;
}
#pageHeader .toggleMenu,
#pageHeader .contacts .container .search form button,
#pageFooter .container .search form button{
  font-size: 12pt;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  background: #E5E5E5;
  border: 0;
}
#pageHeader .toggleMenu,
#pageHeader .contacts .container .search form button:hover,
#pageHeader .contacts .container .search form button:hover{
  cursor: pointer;
}

#pageHeader .toggleMenu{
  width: auto;
  height: 25px;
  padding: 0 10px 0;
}

#pageHeader .logoBlock {
  position: relative;
  max-width: 550px;
  padding: 20px 10px 40px;
  margin: 0 auto;
  text-align: center;
}
#pageHeader .toggleMenu {
  display: none;
}

#pageHeader nav {
  position: relative;
  z-index: 2;
  max-width: 1800px;
  margin: 0 auto;
  padding-bottom: 10px;

}

#pageHeader nav ul {
  position: relative;
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
}

#pageHeader nav ul li {
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}

#pageHeader nav ul li:first-child {
  margin-left: 5px;
}

#pageHeader nav ul li:last-child {
  margin-right: 5px;
}

#pageHeader nav ul li a {
  display: inline-block;
  line-height: 14pt;
  color: #FFFFFF;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 10px 20px;
}
#pageHeader nav ul li a:hover {
  color: #E6DBD0;
}

#pageHeader nav ul li.active a {
  background-color: #230029;
  border-radius: 5px;
}

#pageHeader nav ul li:hover a {
  color: #E6DBD0;
}

#pageHeader nav ul li:hover ul,
#pageHeader nav ul li.toggled ul{
  display: block;
}

#pageHeader nav > ul > li > ul {
  width: auto;
  position: absolute;
  display: none;
  background-color: #E6DBD0;
  border-top: 15px solid #5D125B;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: 0 8px 6px -6px #5D125B;
  -moz-box-shadow: 0 8px 6px -6px #5D125B;
  box-shadow: 0 8px 6px -6px #5D125B;
}

#pageHeader nav > ul > li > ul.full-width {
  width: 100%;
  left: 0;
  overflow: hidden;
}

#pageHeader nav > ul > li > ul a {
  padding: 0 15px;
  margin: 10px 0;
  font-size: 12pt;
  line-height: 14pt;
  text-transform: none;
  color: #5D125B !important;
  text-decoration: underline;
  white-space: pre-wrap;
  text-align: left;
}

#pageHeader nav > ul > li > ul a:hover {
  color: #5D125B;
  text-decoration: none;
}

#pageHeader nav ul ul li {
  display: flex;
  gap: 15px;
  align-items: stretch;
}

#pageHeader nav ul ul li ul {
  width: 100%;
  position: relative;
  background-color: transparent;
  border: 0;
  padding: 0;
}

#pageHeader nav ul ul li ul h4 {
  text-align: left;
  margin: 15px 0 5px 10px;
}


/*Content section*/

#pageFooter {
  grid-area: footer;
}
#mainSection {
  max-width: 1800px;
  width: 100%;
  margin: 10px auto;
  grid-area: mainSection;
  display: grid;
  grid-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas:
    "left article right";
}

#mainSection > * {
  height: 100%;
}

#mainArticle {
  grid-area: article;
}

#mainArticle .news-wrapper {
  display: flex;
  flex-wrap: wrap;
}

#mainArticle .news-container {
  flex: 1;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

#mainArticle .news-container:first-child {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
  overflow: hidden;
}

#mainArticle .news-container:first-child .news-item {
  margin-top: 0;
}

#mainArticle .news-item {
  margin: 20px 15px;
  background-color: #F9F6F3;
  -webkit-box-shadow: 0px 2px 5px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 2px 5px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 2px 5px 2px rgba(34, 60, 80, 0.2);
  border-radius: 5px;
}

#mainArticle .news-item .image {
  width: 100%;
  height: 225px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

#mainArticle .news-container:first-child .news-item .image {
  height: 300px;
}

#mainArticle .news-item .title {
  display: block;
  padding: 10px;
  min-height: 40px;
  font-size: 13pt;
  line-height: 15pt;
}

#sideLeft {
  grid-area: left;
  padding: 0 10px;
}

#sideLeft nav {
  background-color: #F9F6F3;
  border-radius: 5px;
  padding: 10px;
  -webkit-box-shadow: 0px 2px 5px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 2px 5px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 2px 5px 2px rgba(34, 60, 80, 0.2);
}

#sideLeft nav ul {
  padding: 0;
  margin: 10px 0 0 0;
  list-style: none;
  color: #6A007A;
  font-size: 24px;
}

#sideLeft nav ul li {
  padding: 5px 0;
  display: flex;
}

#sideLeft nav ul li i {
  margin: auto 10px auto 0;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  text-align: center;
}

#sideLeft nav ul li a {
  display: block;
  color: #000000;
  font-size: 14pt;
  margin: auto 0;
}

#sideLeft nav ul li.promo {
  color: #EE5817;
  text-transform: uppercase;
  font-weight: bold;
}

#sideLeft nav ul li.promo a {
  color: #EE5817;
  font-size: 16pt;
}

#sideRight {
  grid-area: right;
  padding: 0 10px;
}

.owl-carousel .animated{
  visibility:visible;
}
.owl-item .animated {
  visibility:hidden;
}
.owl-item .animated.visible {
  visibility:visible;
}
.owl-carousel .owl-item img{
  width: auto;
}
/* Owl navigation */
.owl-carousel .owl-controls .owl-nav {
  margin-top: 0;
}
.owl-controls .owl-nav > div {
  font-size:0;
  padding:0;
  background:#fff;
  color:#606060;
  margin:0;
  text-align:center;
  height: 40px;
  line-height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  -webkit-transform:translateY(-50%);
  -moz-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  -o-transform:translateY(-50%);
  transform:translateY(-50%);
}
.owl-carousel .owl-controls .owl-nav .owl-prev {
  left: 15px;
  margin-right:7.5px;
}
.owl-carousel .owl-controls .owl-nav .owl-next {
  right: 15px;
  margin-left:7.5px;
}
.owl-carousel .owl-controls .owl-nav .owl-prev:after,
.owl-carousel .owl-controls .owl-nav .owl-next:after {
  content: "\e6ab";
  font-family: universh-icon;
  position:absolute;
  font-size:20px;
  font-weight:800;
  color:inherit;
  left:0;
  right:0;
}
.owl-carousel .owl-controls .owl-nav .owl-next:after {
  content: "\e6ac";
}
.owl-theme.nav-dark .owl-nav > div{
  background:#606060;
  color: #fff;
}
.nav-mini .owl-controls .owl-nav > div{
  height: 30px;
  width: 30px;
  line-height: 30px;
}
.owl-carousel.nav-mini  .owl-controls .owl-nav .owl-prev:after,
.owl-carousel.nav-mini  .owl-controls .owl-nav .owl-next:after{
  font-size: 16px;
  font-weight: bold;
}
.owl-carousel.nav-topright .owl-controls .owl-nav div,
.owl-carousel.nav-topleft .owl-controls .owl-nav div {
  position: absolute;
  top: -70px;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.owl-carousel.nav-topright .owl-controls .owl-nav .owl-prev, .owl-carousel.nav-topright .owl-controls .owl-nav .owl-next {
  right: 0;
  left: auto;
  margin: 0;
}
.owl-carousel.nav-topright .owl-controls .owl-nav .owl-prev{
  right: 50px;
}
.owl-carousel.nav-topleft .owl-controls .owl-nav .owl-prev, .owl-carousel.nav-topleft .owl-controls .owl-nav .owl-next {
  left: 0;
  right: auto;
  margin: 0;
}
.owl-carousel.nav-topleft .owl-controls .owl-nav .owl-next{
  left: 50px;
}

/* Owl Pagination */
.owl-carousel.dots-inline .owl-dots {
  bottom: 10px;
  left: 0;
  position: absolute;
  right: 0;
}
.owl-theme .owl-dots .owl-dot span{
  height: 8px;
  width: 8px;
  background: #fff;
}
.owl-theme .owl-dots .owl-dot.active span{
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4);
}
.owl-theme.dots-dark .owl-dots .owl-dot span{
  background: #252525;
}

.widget{
  background-color: #fff;
  margin: 40px 0;
}

.widget ul{
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.widget li a{
  color: #606060;
}
.widget-title {
  text-transform: uppercase;
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 20px;
}
.widget-title span {
  background: #42004C none repeat scroll 0 0;
  display: block;
  height: 1px;
  margin-top: 15px;
  position: relative;
  width: 20%;
}
.widget-title span::after {
  background: inherit;
  content: "";
  height: inherit;
  position: absolute;
  top: -4px;
  width: 50%;
}
.widget-title.text-center span,
.widget-title.text-center span::after {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.widget .badge{
  float: right;
  background: #7f7f7f;
}

.event-widget {
  background-color: #E6DBD0;
  padding: 0 0 20px !important;
}

.event-widget.important {
  background-color: orangered;
}

.event-widget .daycounter {
  height: auto;
  padding: 20px 0 30px;
}

.event-widget .daycounter.is-countdown {
  border: 1px solid #5D125B;
  background-color: #42004C;
  color: #fff;
}

.event-widget h5 {
  font-style: normal;
  font-size: 14pt;
  line-height: 18pt;
  text-align: center;
  padding: 0 20px;
}

.event-widget.important h5 {
  color: #fff;
}

.event-widget p,
.event-widget h5 a{
  color: #5D125B;
}

.event-widget.important p,
.event-widget.important h5 a{
  color: #fff;
}

.last-news {
  font-size: 14pt;
}

.last-news li {
  margin: 20px 0;
}

.partner-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}

.partner-links li {
  height: 100px;
}

.partner-links li img{
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.mt-0 {
  margin-top: 0 !important;
}

#pageFooter {
  color: #fff;
}

#pageFooter .students {
  width: 100%;
  height: 50px;
  background-color:#fff;
  background-image: url("../img/footer-bg.png");
  background-repeat: repeat-x;
  background-position: bottom center;
}

#pageFooter .footer{
  width: 100%;
  background-color: #42004C;
}

#pageFooter .container {
  max-width: 1800px;
  padding: 30px 10px;
  margin: 0 auto 0;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  gap: 15px;
}

#pageFooter .container .widget {
  background-color: transparent;
}

#pageFooter .container .widget-title span {
  background-color: #fff;
}

#pageFooter .container .widget ul {
  font-size: 20px;
  font-weight: normal;
}

#pageFooter .container .widget ul li {
  margin: 15px 0;
}

#pageFooter .container .widget ul li i {
  margin: auto 10px auto 0;
  width: 20px;
  text-align: center;
}

#pageFooter .container a {
  color: #fff;
}

#pageFooter .container .search {
  width: 100%;
}
